








































































































































































































































































































































































































































































































































































































































































































import { computed, watch, ref, Ref, defineAsyncComponent, onMounted } from '@vue/composition-api';
import store, { useUserState, useUserActions, useUserGetters } from '@/store';
import _, { filter, flattenDepth, isArray } from 'lodash';
import { ActionTypes } from '@/store/modules/tools/actions';
import useTeamDoc from '@/composables/useTeamDoc';
import utils from '@/utils/utilities';

export default {
  components: {
    AHeading: () => import('@/components/atoms/AHeading.vue'),
    AAutocomplete: () => import('@/components/atoms/AAutocomplete.vue'),
    ATextInput: () => import('@/components/atoms/ATextInput.vue'),
    AButton: () => import('@/components/atoms/AButton.vue'),
    ATextArea: () => import('@/components/atoms/ATextArea.vue'),
    ACombobox: () => import('@/components/atoms/ACombobox.vue'),
    ACheckbox: () => import('@/components/atoms/ACheckbox.vue'),
    ARangeSlider: () => import('@/components/atoms/ARangeSlider.vue'),
    ARadioGroup: () => import('@/components/atoms/ARadioGroup.vue'),
    ACheckBoxGroup: () => import('@/components/atoms/ACheckBoxGroup.vue'),
    ARadioButton: () => import('@/components/atoms/ARadioButton.vue'),
    ADivider: () => import('@/components/atoms/ADivider.vue'),
    AVSelect: () => import('@/components/atoms/a-v-select.vue'),
    AToolbar: () => import('@/components/atoms/a-toolbar.vue'),
    ASubheader: () => import('@/components/atoms/a-subheader.vue'),
    ATeleInput: () => import('@/components/atoms/ATeleInput.vue'),
    MTimelineTree: () => import('@/components/molecules/m-timeline-tree.vue'),
    MListItem: () => import('@/components/molecules/m-list-item.vue'),
    MAcknowledgeBtn: () => import('@/components/molecules/m-acknowledge-btn.vue'),
    MTeam: () => import('@/components/molecules/m-team.vue'),
    MDataTable: () => import('@/components/molecules/m-data-table.vue'),
    MVideoLinksViewer: () => import('@/components/molecules/m-video-links-viewer.vue'),
    MResourceLinksViewer: () => import('@/components/molecules/m-resource-links-viewer.vue'),
    MProjectContent: () => import('@/components/molecules/m-project-content.vue'),
    MProgramFaq: () => import('@/components/molecules/program-faq/m-program-faq.vue'),
    MPresentation: () => import('@/components/molecules/deliverable/m-presentation.vue'),
    MProjectImplementation: () =>
      import('@/components/molecules/deliverable/m-project-implementation.vue'),
    MMinutesLog: () => import('@/components/molecules/m-minutes-log.vue'),
    MPhoneVerifyDialog: () => import('@/components/molecules/m-verify-phone-dialog.vue'),
    MVideoaskGenration: () => import('@/components/molecules/m-videoask-generation.vue'),
    MetaGrabber: () => import('@/components/molecules/m-meta-grabber.vue'),
    ADatePicker: () => import('@/components/atoms/ADatePicker.vue'),
    MWelcomeAdk: () => import('@/components/molecules/m-welcome-adk.vue'),
    MVideoask: () => import('@/components/molecules/m-videoask.vue'),
    MContactAdk: () => import('@/components/molecules/m-contact-adk.vue'),
    MMeetAdk: () => import('@/components/molecules/m-meet-adk.vue'),
    MDeliverableAdk: () => import('@/components/molecules/deliverable/m-deliverable-adk.vue'),
    MWorkspaceAdk: () => import('@/components/molecules/m-workspace-adk.vue'),
    MCheckoutForm: () => import('@/components/molecules/m-checkout-form.vue'),
    MVideoaskRecord: () => import('@/components/molecules/m-videoask-record.vue'),
    MDiscord: () => import('@/components/molecules/m-discord.vue'),
    MOfferTable: () => import('@/components/molecules/m-offer-table.vue'),
    MContactDefault: () => import('@/components/molecules/m-contact-default.vue'),
    MDeliverableDefault: () =>
      import('@/components/molecules/deliverable/m-deliverable-default.vue'),
    MTimesheetDefault: () => import('@/components/molecules/m-timesheet-default.vue'),
    MMeetDefault: () => import('@/components/molecules/m-meet-default.vue'),
    mSaveAdkOnly: () => import('@/components/molecules/m-save-adk-only.vue'),
    MMatterport: () => import('@/components/molecules/m-matterport.vue'),
    MActivityFeedbackForm: () =>
      import('@/components/molecules/activity-settings/m-activity-feedback-form.vue'),
    MAirtable: () => import('@/components/molecules/m-airtable.vue')
  },
  props: {
    data: {
      type: Array,
      default: () => {},
      required: true
    },
    program: {
      type: Object,
      default: () => {}
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    userType: {
      required: true,
      type: String
    },
    userDoc: {
      required: false,
      default: () => {}
    },
    adkColor: {
      type: String,
      default: ''
    },
    useScope: {
      type: Function,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    ages: {
      type: Array,
      default: () => {}
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    adkType: {
      type: String,
      default: ''
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    db: {
      required: false,
      default: () => {}
    },
    setupRequired: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    isVisitor: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },

  setup(props: any, ctx: any) {
    const validation: any = ref();
    const isVisible = ref(true);
    const { updateAdkData, updateCompleted, addBadges } = useUserActions([
      'updateAdkData',
      'updateCompleted',
      'addBadges'
    ]);
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { user }: any = useUserState(['user']);
    const discord = ref();
    const remainingQuestion = ref();
    const updatedDiscordData = ref();
    const validationRules = [
      { name: 'm-discord' },
      { name: 'm-videoask-generation' },
      {
        name: 'm-videoask'
      },
      { name: 'metagrabber' },
      { name: 'm-meet-adk' },
      { name: 'm-welcome-adk' },
      { name: 'm-team' },
      { name: 'm-program-faq' },
      { name: 'm-project-implementation' },
      { name: 'm-presentation' },
      { name: 'm-timesheet-default' },
      { name: 'm-minutes-log' },
      { name: 'm-matterport' }
    ];
    const isSaving = ref(false);
    const isCompleting = ref(false);
    const isChannelNotCreated = ref(false);
    const checkboxVal = ref();
    const isCheckBoxChecked = ref(true);
    const teamDoc = ref();
    const { getTeamDoc }: any = useTeamDoc();
    const teamDocument = ref();
    const isTeam = ref(true);
    const isMinuteLogged = ref(true);
    const discordData = ref();
    const discordInfo = ref();
    const practiceLog = ref(0);
    const minimumHours = ref(0);
    const loglength = ref(0);
    const isFieldsValidated = ref(false);
    const isSaveBtnEnabled = ref(false);
    const isShowFeedBackForm = ref(false);
    const completedMolecules = ref<
      Array<{
        name: string;
        completed: boolean;
        length?: number;
      }>
    >([]);
    const disableComplete = ref(true);

    const checkLogMinutes = computed(() => {
      if (minimumHours.value > practiceLog.value && practiceLog.value !== 0) {
        return minimumHours.value - practiceLog.value;
      }
      return practiceLog.value <= 0 ? minimumHours.value : 0;
    });

    const programDoc = computed({
      get: () => props.program,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const userTypeValue = computed(() => {
      if (
        props.userType === 'organizer' &&
        ctx.root.$route.name !== 'Monitor' &&
        !props.isPreview
      ) {
        return 'employer';
      }
      if (props.isPreview) return 'participant';
      if (props.isVisitor) return 'employer';
      return 'participant';
    });

    const getTeamDocument = async () => {
      try {
        const doc = await getTeamDoc({
          program_id: ctx.root.$route.params?.programId || programDoc.value?.data?.program_id,
          user_id: props.isMonitor ? props?.studentId : user.value?._id?.toString(),
          public_id:
            userTypeValue?.value === 'participant' ? props.value?.data?._id.toString() : null
        });
        return doc;
      } catch (error) {
        console.log('error while fetching program doc.', error);
        console.error(error);
      }
      return false;
    };

    const moleculeCompleted = computed(() => {
      return completedMolecules.value.every(molecule => molecule.completed);
    });

    const keyValue = computed(() => {
      if (userTypeValue.value === 'participant') {
        return 'value';
      }
      return 'setup';
    });

    const calculateRange = (min, max) => {
      return _.range(min, max);
    };

    const programIdValue: any = computed(() => {
      if (userTypeValue?.value === 'participant') {
        return programDoc?.value?.data?.program_id?.toString();
      }
      return programDoc?.value?.data?._id?.toString();
    });

    const getAdkFromIndex = computed(() => {
      return programDoc?.value?.data?.adks[props.adkIndex];
    });

    const getFilteredValues = (fields, userType) => {
      const filteredFields = Object.values(fields)?.filter(
        (elem: any) =>
          (elem?.roles?.includes?.(userType) &&
            (elem.type === 'molecule' || elem.type === 'molecules')) ||
          (elem.roles?.includes?.('employer') && elem.name === 'metagrabber')
      );
      return filteredFields;
    };

    const getFieldsByUserType = (userType: string) => {
      const filteredFields = getAdkFromIndex.value?.fields?.filter?.(
        elem => elem?.roles?.includes?.(userType) || elem.name === 'metagrabber'
      );
      const filteredPresetFields = getAdkFromIndex.value?.presetFields?.filter?.(elem =>
        elem.roles?.includes?.(userType)
      );
      const combinedObject = [...filteredFields, ...filteredPresetFields];
      return combinedObject;
    };

    const getMolecules = () => {
      const { userType } = props;
      const type = userType === 'organizer' ? 'employer' : 'participant';
      const fieldsArrayName = getFieldsByUserType(type);
      if (userType && fieldsArrayName) {
        const filteredValues = getFilteredValues(fieldsArrayName, type);
        return filteredValues;
      }
      return [];
    };

    const isRequired = (field: any) => {
      if (field.rules?.includes?.('required')) {
        return true;
      }
      return false;
    };

    const getCurrentField = (molecules, name) => {
      return molecules?.find?.(molecule => molecule.name === name) || false;
    };

    const getFieldValue = (name: string) => {
      const currentAdk = getAdkFromIndex.value;
      const { fields } = currentAdk;
      return fields?.find(field => field.name === name);
    };

    const checkMoleculeCompleted = computed(() => {
      return (name: string): { completed: boolean; length?: number } => {
        const molecule = completedMolecules.value?.find(molecule => molecule.name === name);
        return molecule || { completed: true };
      };
    });

    const getCompletedIndex = (name: string) => {
      return completedMolecules.value?.findIndex(molecule => molecule.name === name);
    };

    const getTeamField = computed(() => {
      return teamDocument.value?.adks?.find?.(team => team.name === props.adkType);
    });

    const pushToCompleted = (molecule, isCompleted) => {
      const CompletedIndex = getCompletedIndex(molecule.name);
      if (CompletedIndex !== -1) {
        completedMolecules.value[CompletedIndex].completed = isCompleted;
      } else {
        completedMolecules.value.push({
          name: molecule.name,
          completed: isCompleted
        });
      }
    };

    const ValidateMolecules = async () => {
      completedMolecules.value = [];
      const molecules = getMolecules();
      if (molecules?.length > 0) {
        await new Promise<void>(resolve => {
          validationRules.forEach(elem => {
            const currentMolecule = getCurrentField(molecules, elem.name);
            if (currentMolecule) {
              if (currentMolecule.name === 'm-meet-adk') {
                let tokenData;
                if (props?.userType === 'organizer') {
                  const organizer = programDoc?.value?.data?.organizers?.find(
                    org => org?.userId?.toString() === user?.value?._id?.toString()
                  );
                  tokenData = organizer?.googleDetails || organizer?.outlookDetails;
                } else {
                  tokenData =
                    programDoc?.value?.data?.googleDetails ||
                    programDoc?.value?.data?.outlookDetails;
                }
                if (tokenData) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
              } else if (currentMolecule.name === 'm-discord') {
                const { user }: any = useUserState(['user']);
                discordInfo.value =
                  props.userType === 'organizer'
                    ? user.value.workspaceDiscordInfo
                    : currentMolecule.value.discordInfo;
                discordData.value = currentMolecule?.setup?.discordData || updatedDiscordData.value;
                if (discordInfo.value) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
                if (discordInfo.value && !discordData.value) {
                  isChannelNotCreated.value = true;
                } else {
                  isChannelNotCreated.value = false;
                }
              } else if (currentMolecule.name === 'm-videoask-generation') {
                if (currentMolecule.setup?.form_id && currentMolecule.setup?.form_url) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
              } else if (currentMolecule.name === 'm-videoask') {
                if (
                  (currentMolecule.model === 'videoAskResponse' &&
                    currentMolecule.value?.videoAskResponse &&
                    userTypeValue.value === 'participant') ||
                  (currentMolecule.setup?.signInVideoAskResponse &&
                    currentMolecule.model === 'signInVideoAskResponse' &&
                    userTypeValue.value === 'employer')
                ) {
                  pushToCompleted(currentMolecule, true);
                } else if (
                  currentMolecule.model === 'videoAskResponse' ||
                  currentMolecule.model === 'signInVideoAskResponse'
                ) {
                  pushToCompleted(currentMolecule, false);
                }
              } else if (currentMolecule.name === 'm-minutes-log') {
                if (practiceLog.value >= minimumHours.value) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
              } else if (
                currentMolecule.name === 'metagrabber' &&
                userTypeValue.value === 'participant'
              ) {
                if (
                  currentMolecule?.value?.researchProgress &&
                  currentMolecule.model === 'researchLinks'
                ) {
                  const result = currentMolecule.value?.researchProgress
                    ?.filter(item => item?.required)
                    ?.every(
                      (item: any) =>
                        item.required && item.viewed === true && item.completed === true
                    );
                  const newMolecule = { ...currentMolecule };
                  newMolecule.name = 'm-resource-links-viewer';
                  if (result) {
                    pushToCompleted(newMolecule, true);
                  } else {
                    pushToCompleted(newMolecule, false);
                  }
                } else if (currentMolecule.model === 'researchLinks') {
                  const newMolecule = { ...currentMolecule };
                  newMolecule.name = 'm-resource-links-viewer';
                  pushToCompleted(newMolecule, false);
                }
                if (
                  currentMolecule.model === 'videoLinks' &&
                  currentMolecule.value?.trainProgress
                ) {
                  const result = currentMolecule.value?.trainProgress?.every(
                    (item: any) => !item.is_processing && item.completed && item.unlocked
                  );
                  const newMolecule = { ...currentMolecule };
                  newMolecule.name = 'm-video-links-viewer';
                  if (result) {
                    pushToCompleted(newMolecule, true);
                  } else {
                    pushToCompleted(newMolecule, false);
                  }
                } else if (currentMolecule.model === 'videoLinks') {
                  const newMolecule = { ...currentMolecule };
                  newMolecule.name = 'm-video-links-viewer';
                  pushToCompleted(newMolecule, false);
                }
              } else if (
                currentMolecule.name === 'm-welcome-adk' &&
                userTypeValue.value === 'participant'
              ) {
                isCheckBoxChecked.value = checkboxVal.value;
              } else if (
                currentMolecule.name === 'm-program-faq' &&
                userTypeValue.value === 'participant'
              ) {
                if (remainingQuestion.value === 0) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
              } else if (
                currentMolecule?.name === 'm-project-implementation' &&
                userTypeValue.value === 'participant'
              ) {
                const minLogsField = getFieldValue('min_logs');
                const milestoneLogs = minLogsField?.setup?.minLogs || minLogsField?.preset?.minLogs;
                const mProjectField = getFieldValue(currentMolecule.name);
                const filledLogs = mProjectField.value?.logs?.length || 0;
                if (milestoneLogs === filledLogs) {
                  pushToCompleted(currentMolecule, true);
                  loglength.value = milestoneLogs - filledLogs;
                } else if (milestoneLogs !== filledLogs) {
                  pushToCompleted(currentMolecule, false);
                  loglength.value = milestoneLogs - filledLogs;
                }
              } else if (
                currentMolecule.name === 'm-presentation' &&
                userTypeValue.value === 'participant'
              ) {
                const CompletedIndex = getCompletedIndex(currentMolecule.name);
                const teamField = getTeamField.value;
                if (teamField?.submittedVideo) {
                  if (CompletedIndex !== -1) {
                    completedMolecules.value[CompletedIndex].completed = true;
                  } else {
                    completedMolecules.value.push({
                      name: currentMolecule.name,
                      completed: true
                    });
                  }
                } else if (!teamField?.submittedVideo) {
                  if (CompletedIndex === -1) {
                    completedMolecules.value.push({
                      name: currentMolecule.name,
                      completed: false
                    });
                  } else {
                    completedMolecules.value[CompletedIndex].completed = false;
                  }
                }
              } else if (
                currentMolecule.name === 'm-timesheet-default' &&
                userTypeValue.value === 'participant'
              ) {
                const sheetDetails = currentMolecule.value;
                const lastInteraction = sheetDetails?.lastInteraction;
                if (
                  (lastInteraction === 'clock_in' || lastInteraction === 'clock_out') &&
                  sheetDetails?.timesheetDetails?.length >= 1
                ) {
                  isSaveBtnEnabled.value = true;
                  pushToCompleted(currentMolecule, true);
                } else {
                  isSaveBtnEnabled.value = false;
                  pushToCompleted(currentMolecule, false);
                }
              } else if (
                currentMolecule.name === 'm-team' &&
                userTypeValue.value === 'participant'
              ) {
                if (teamDoc.value && teamDoc.value.status !== 400) {
                  isTeam.value = true;
                } else {
                  isTeam.value = false;
                }
              } else if (
                currentMolecule.name === 'm-matterport' &&
                userTypeValue.value === 'employer'
              ) {
                if (currentMolecule?.setup[currentMolecule?.model]) {
                  pushToCompleted(currentMolecule, true);
                } else {
                  pushToCompleted(currentMolecule, false);
                }
              }
            }
          });
          resolve();
        });
      }
      return true;
    };

    const videoAskUrl = computed(() => {
      return url =>
        `${url}#contact_name=${encodeURI(user?.value?.firstName)}&contact_email=${encodeURI(
          user?.value?.email
        )}&contact_phone_number=${encodeURI(user?.value?.phoneNumber)}&program_id=${encodeURI(
          programIdValue?.value
        )}&adk_name=${encodeURI(props.adkType)}&index=${props.adkIndex}`;
    });

    const validateForm = () => {
      setTimeout(() => {
        validation.value.validate({ silent: true }).then(async valid => {
          isFieldsValidated.value = valid;
          if (
            isFieldsValidated.value &&
            moleculeCompleted.value &&
            getAdkFromIndex.value?.isSaved
          ) {
            if (props.adkType === 'team') {
              if (teamDoc?.value && teamDoc?.value?.status !== 400) {
                disableComplete.value = false;
              } else {
                disableComplete.value = true;
              }
            } else {
              disableComplete.value = false;
            }
          } else {
            disableComplete.value = true;
          }
        });
      }, 500);
    };

    const payloadData = computed(() => {
      if (!props.isTemplate) {
        ValidateMolecules();
        validateForm();
      }
      return _.sortBy(props.data, 'position');
    });
    function pascalCaseToHyphenSeparated(input) {
      return input
        .replace(/([a-z])([A-Z])/g, '$1-$2') // Insert hyphens between lowercase and uppercase letters
        .replace(/([A-Z])([A-Z][a-z])/g, '$1-$2') // Insert hyphens between consecutive uppercase letters followed by a lowercase letter
        .toLowerCase(); // Convert the entire string to lowercase
    }

    const currentActivityFields: Ref<any> = ref({});
    const renderCustomActivity = async () => {
      if (currentActivityFields.value) currentActivityFields.value = {};
      const fields = payloadData.value?.map(field => field.name);
      const resolvedFields = (
        await Promise.allSettled(
          fields?.map(async field => {
            try {
              const { default: activity } =
                (await import(`./activities/${field}.vue`)) ||
                (await import(`./activities/${field}`));
              if (activity) return activity;
              return {};
            } catch (e) {
              // Check if the error is a file not found error
              if (e.code === 'MODULE_NOT_FOUND') {
                try {
                  const { default: activity } = await import(`./activities/${field}`);
                  return activity;
                } catch (err) {
                  // Handle the case where both imports fail
                  if (err.code === 'MODULE_NOT_FOUND') {
                    return {};
                  }
                  // Rethrow the error if it's not a file not found error
                  throw err;
                }
              }
              // Rethrow the error if it's not a file not found error
              throw e;
            }
          })
        )
      ).map(result => (result.status === 'fulfilled' ? result.value : {}));
      currentActivityFields.value = resolvedFields.reduce((acc, field) => {
        if (!Object.keys(field).length || !field.name) return acc;
        const fieldName = pascalCaseToHyphenSeparated(field.name);
        acc[fieldName] = field;
        return acc;
      }, {});
    };
    watch(payloadData, renderCustomActivity);
    onMounted(renderCustomActivity);

    const configureAdkData = (save = false) => {
      if (programDoc.value?.data?.adks[props.adkIndex]?.setupRequired) {
        programDoc.value.data.adks[props.adkIndex].fields = payloadData.value;
      } else {
        programDoc.value.data.adks[props.adkIndex].presetFields = payloadData.value;
      }
      if (!save) programDoc.value.data.adks[props.adkIndex].completed = new Date();
      return programDoc.value.data.adks[props.adkIndex];
    };

    const getDocumentType = computed(() => {
      const origin = window.location.href;
      const value = origin.split('/');
      let docType;
      if (props.userType === 'organizer' && value && value[3] === 'template') {
        docType = 'ProgramSchema';
      } else if (props.userType === 'organizer' && value && value[3] !== 'template') {
        docType = 'Program';
      } else if (props.userType !== 'organizer') {
        docType = 'Student';
      }
      return docType;
    });

    const completeAdk = async () => {
      ctx.emit('isSaveProgress', 'save progress');
      const currentAdk = getAdkFromIndex.value;
      // const isMoleculesCompleted = moleculeCompleted.value;
      if (currentAdk) {
        isCompleting.value = true;
        const complete = await updateAdkData({
          program_id: programDoc.value.data._id.toString(),
          document_type: getDocumentType.value,
          data: configureAdkData()
        });
        if (complete && !complete.data.error) {
          let badges;
          if (
            !currentAdk?.isBadgesAdded &&
            ((programDoc?.value?.data?.credentials &&
              programDoc?.value?.data?.credentials?.length >= 1 &&
              programDoc?.value?.data?.credentials?.filter(cred =>
                cred?.activities?.includes(currentAdk?.name)
              )?.length >= 1) ||
              (programDoc?.value?.data?.rewards &&
                typeof programDoc?.value?.data?.rewards !== 'string' &&
                programDoc?.value?.data?.rewards?.length >= 1 &&
                programDoc?.value?.data?.rewards?.filter(reward =>
                  reward?.activities?.includes(currentAdk?.name)
                )?.length >= 1) ||
              (currentAdk?.credentials && currentAdk?.credentials?.length >= 1) ||
              (currentAdk?.rewards && currentAdk?.rewards?.length >= 1))
          ) {
            badges = await addBadges({
              user_id: user?.value?._id?.toString(),
              student_id: programDoc.value.data._id.toString(),
              adk_name: currentAdk?.name
            });
          }
          ctx.emit('isSaveProgress', 'save progress');
          await props.fetchProgram();
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Activity completed successfully!',
            type: 'success',
            isShowSnackbar: true
          });
          if (badges?.data) {
            setTimeout(() => {
              store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
                message: badges?.message,
                type: 'success',
                isShowSnackbar: true
              });
            }, 2000);
          }
          isCompleting.value = false;
          if (currentAdk?.isShowFeedBackForm) {
            setTimeout(() => {
              isShowFeedBackForm.value = true;
            }, 2500);
          }
          setTimeout(() => {
            if (props.userType === 'participant') {
              ctx.emit('complete-activity');
            }
          }, 1000);
        } else {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'make sure you have an account first (error completing step)',
            type: 'error',
            isShowSnackbar: true
          });
          isCompleting.value = false;
        }
        // validation.value.validate().then(async valid => {
        //   if (
        //     valid &&
        //     isMoleculesCompleted &&
        //     isCheckBoxChecked.value &&
        //     isTeam.value &&
        //     !(isChannelNotCreated.value && userTypeValue.value === 'employer')
        //   ) {
        //     console.log('completed');
        //     // const complete = await updateAdkData({
        //     //   program_id: programDoc.value.data._id.toString(),
        //     //   document_type: getDocumentType.value,
        //     //   data: configureAdkData()
        //     // });
        //     // if (complete && !complete.data.error) {
        //     //   ctx.emit('isSaveProgress', 'save progress');
        //     //   props.fetchProgram();
        //     //   store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        //     //     message: complete?.message,
        //     //     type: 'success',
        //     //     isShowSnackbar: true
        //     //   });
        //     //   isCompleting.value = false;
        //     // } else {
        //     //   store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        //     //     message: 'error completing the step',
        //     //     type: 'error',
        //     //     isShowSnackbar: true
        //     //   });
        //     //   isCompleting.value = false;
        //     // }
        //   } else {
        //     isCompleting.value = false;
        //   }
        // });
      }
    };

    // watch(
    //   payloadData.value,
    //   async () => {
    //     isChannelNotCreated.value = false;
    //     isCheckBoxChecked.value = true;
    //     if (programDoc.value?.data?.adks[props.adkIndex]?.setupRequired) {
    //       programDoc.value.data.adks[props.adkIndex].fields = payloadData.value;
    //     } else {
    //       programDoc.value.data.adks[props.adkIndex].presetFields = payloadData.value;
    //     }
    //     // completeAdk();
    //   },
    //   { deep: true }
    // );

    const externDialog = ref(false);
    const isShowDialog = ref(false);
    const isDialog = ref(false);

    function setValidation(val) {
      if (val) {
        return val.join('|');
      }
      return '';
    }

    const component = ref();
    const dialog = ref();
    const childComponent = ref();
    const isUpdating = ref(false);
    const alert = ref('');
    const type = ref('');

    function getModalData(val) {
      programDoc.value = { ...val };
    }

    function updateProgramDoc() {
      const adks = getProgramDoc?.value?.adks?.filter(adk => adk.status);
      getProgramDoc.value.adks = adks;
      programDoc.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
    }

    const currentAdkData = computed(() => {
      return programDoc.value?.data?.adks[props.adkIndex];
    });

    async function updateProgramAdk(save = false) {
      type.value = '';
      alert.value = '';
      const documentTypeValue = getDocumentType.value;
      try {
        if (save) isSaving.value = true;
        else isUpdating.value = true;
        const savedData = configureAdkData(save);
        if (!savedData?.isSaved) {
          savedData.isSaved = true;
        }
        const res = await updateAdkData({
          program_id: programDoc.value.data._id.toString(),
          document_type: documentTypeValue,
          data: savedData
        });
        if (res && !res.data.error) {
          ctx.emit('isSaveProgress', 'save progress');
          programDoc.value.data = {
            adks: [],
            ...getProgramDoc.value
          };
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Your progress saved successfully!',
            type: 'success',
            isShowSnackbar: true
          });
          isSaving.value = false;
          isUpdating.value = false;
          await props.fetchProgram();
          validateForm();
        } else if (
          res.data.error.description === 'Program with this name already exists!' ||
          res.data.error.description === 'Template with this name already exists!'
        ) {
          isUpdating.value = false;
          isSaving.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: res.data.error.description,
            type: 'error',
            isShowSnackbar: true
          });
        }
      } catch (err: any) {
        isSaving.value = false;
        isUpdating.value = false;
        payloadData.value.find(adk => adk.name === 'btn_save').styling.loading = undefined;
        alert.value = err?.response?.data?.error?.description;
        type.value = 'Error';
      }
    }

    const confirmationField = ref();

    function checkEvents(val) {
      if (val.label === 'Close') {
        const fieldIndex = programDoc.value.data.adks[props.adkIndex].fields.findIndex(field => {
          if (field.model) {
            return field.model === val.model;
          }
          return false;
        });
        if (fieldIndex) {
          programDoc.value.data.adks[props.adkIndex].fields[fieldIndex].setup = '';
        }
      }
      isDialog.value = false;
    }

    async function clickEvent(field) {
      switch (field.action) {
        case 'launch_dialog':
          dialog.value = defineAsyncComponent(
            () => import(`@/components/molecules/${field.event.component}.vue`)
          );
          isDialog.value = true;
          confirmationField.value = field.event;
          break;
        case 'launch_scope':
          if (field.target === 'scope_setup') {
            childComponent.value = defineAsyncComponent(
              () => import('@/components/molecules/m-build-scope.vue')
            );
            component.value = defineAsyncComponent(
              () => import('@/components/molecules/m-dialog.vue')
            );
            isShowDialog.value = true;
          }
          break;
        case 'refresh_program_doc':
          await props.fetchProgram();
          break;
        case 'save_adk':
          updateProgramAdk();
          break;
        case 'link_redirect':
          if (field.href.includes('https://')) {
            window.open(field.href, field.blank ? '_blank' : '_self');
          } else {
            window.open(`https://${field.href}`, field.blank ? '_blank' : '_self');
          }
          break;
        default:
          break;
      }
    }

    function setPlace(e, field) {
      payloadData.value.find(v => v.model === field.model)[keyValue.value][field.model] = '';
      if (e.target) {
        payloadData.value.find(v => v.model === field.model)[keyValue.value][field.model] =
          e.target.value;
      } else if (e.formatted_address) {
        payloadData.value.find(v => v.model === field.model)[keyValue.value][field.model] =
          e.formatted_address;
      }
    }

    function handleFormEmbedUrlUpdate(newUrl) {
      const airtableAdk = programDoc.value.data.adks.find(adk => adk.name === 'Airtable');
      if (airtableAdk) {
        airtableAdk.formEmbedUrl = newUrl;
      }
    }

    return {
      currentActivityFields,
      getModalData,
      setPlace,
      isShowDialog,
      programDoc,
      utils,
      clickEvent,
      component,
      childComponent,
      setValidation,
      isUpdating,
      alert,
      type,
      isDialog,
      dialog,
      externDialog,
      checkEvents,
      validation,
      payloadData,
      confirmationField,
      updateProgramAdk,
      isSaving,
      userTypeValue,
      updateProgramDoc,
      calculateRange,
      keyValue,
      videoAskUrl,
      currentAdkData,
      completeAdk,
      disableComplete,
      isCompleting,
      discord,
      isChannelNotCreated,
      isCheckBoxChecked,
      checkboxVal,
      teamDoc,
      isTeam,
      isMinuteLogged,
      remainingQuestion,
      completedMolecules,
      checkMoleculeCompleted,
      teamDocument,
      updatedDiscordData,
      minimumHours,
      practiceLog,
      checkLogMinutes,
      loglength,
      validateForm,
      isRequired,
      isVisible,
      isSaveBtnEnabled,
      isShowFeedBackForm,
      handleFormEmbedUrlUpdate
    };
  }
};
