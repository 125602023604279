import actions from './actions';

export default {
  actionHandler(type) {
    switch (type) {
      case 'launch_dialog':
        actions.launchModal();
        break;
      default:
        break;
    }
  }
};
