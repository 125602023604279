import { ref } from '@vue/composition-api';
import { ActionTypes } from '../store/modules/user/actions';
import store from '../store';

export default function useTeamDoc() {
  const teamDoc = ref(null);
  const teamList = ref([]);
  const isFetching = ref(false);
  const getTeamDoc = async data => {
    isFetching.value = true;
    const res = await store.dispatch(`user/${ActionTypes.fetchTeamDoc}`, data);
    teamDoc.value = res;
    return res;
    isFetching.value = false;
  };

  const getTeamList = async data => {
    isFetching.value = true;
    const res = await store.dispatch(`user/${ActionTypes.fetchTeamList}`, data);
    teamList.value = res;
    return res;
    isFetching.value = false;
  };
  return { getTeamDoc, teamDoc, getTeamList, teamList };
}
